.ellipsis-three {
overflow: hidden;
max-height: 60px;
line-height: 20px;
margin: 10px;
}
.ellipsis-three:before {
content:"";
float: left;
width: 20px;
height: 60px;
}
.ellipsis-five {
overflow: hidden;
max-height: 100px;
line-height: 20px;
margin: 10px;
}
.ellipsis-five:before {
content:"";
float: left;
width: 20px;
height: 100px;
}
.ellipsis-three > *:first-child {
float: right;
width: 100%;
margin-left: -30px;
word-break:break-all;
}
.ellipsis-five > *:first-child {
    float: right;
    width: 100%;
    margin-left: -30px;
    word-break:break-all;
    }     
.ellipsis-after {
content: "\02026"; 
box-sizing: content-box;
-webkit-box-sizing: content-box;
-moz-box-sizing: content-box;
float: right;
position: relative;
top: -20px;
left: 100%;
width: 50px;
margin-left: -50px;
padding-right: 30px;
text-align: right;
background-size: 100% 100%;        
background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
}